<template>
  <div style="height: 100%">
    <div style="padding: 20px; height: 800px">
      <!-- 搜素框 -->
      <el-form
        :inline="true"
        :model="params"
        style="
          padding-top: 20px;
          padding-left: 20px;
          margin-bottom: 20px;
          background-color: #fff;
          border-radius: 8px;
        "
        size="small"
      >
        <el-form-item label="快捷用语">
          <el-input
            v-model="params.quickWord"
            placeholder="请输入快捷用语"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button @click="checkData" type="primary" size="mini"
            >查询</el-button
          >
          <el-button type="info" @click="reset" size="mini">重置</el-button>
        </el-form-item>
      </el-form>
      <!-- 表格 -->
      <div style="height: 82%; background-color: #fff; border-radius: 8px;padding-top: 8px;">
        <el-button
          type="primary"
          size="small"
          style="
            float: right;
            margin-top: 20px;
            margin-right: 20px;
            margin-bottom: 20px;
          "
          @click="add()"
          >新增</el-button
        >
        <el-table
          :data="tableData"
          stripe
          style="padding: 0 20px"
          height="80%"
          size="small"
          :header-cell-style="{
            'background-color': '#84aace4a',
            color: '#343333',
            'text-align': 'center',
          }"
          :cell-style="{ 'text-align': 'center' }"
        >
          <el-table-column
            prop="quickWord"
            sortable
            label="快捷用语"
          ></el-table-column>
          <el-table-column
            prop="createTime"
            sortable
            label="创建时间"
            :formatter="dateFormat"
          ></el-table-column>
          <el-table-column
            prop="orderIndex"
            sortable
            label="排序"
          ></el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-tooltip effect="dark" content="编辑" placement="top-end">
                <el-button
                  type="primary"
                  icon="el-icon-edit"
                  circle
                  size="mini"
                  @click="edit(scope.row)"
                  style="margin-right: 5px"
                ></el-button>
              </el-tooltip>
              <el-tooltip effect="dark" content="删除" placement="top-end">
                <el-popconfirm
                  title="确认删除吗？"
                  @confirm="deleteRegion(scope.row)"
                >
                  <el-button
                    content="删除"
                    slot="reference"
                    type="danger"
                    icon="el-icon-delete"
                    circle
                    size="mini"
                  ></el-button>
                </el-popconfirm>
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table>
        <div
          style="
            padding-top: 20px;
            height: 40px;
            background-color: #fff;
            width: 100%;
            border-radius: 8px;
            text-align: right;
          "
        >
          <el-pagination
            background
            :page-sizes="sizes"
            layout="total,sizes,prev,pager,next"
            :total="total"
            @current-change="handleCurrentChange"
            @size-change="sizeChange"
          ></el-pagination>
        </div>
      </div>
    </div>
    <!-- 弹出层 -->
    <el-dialog
      title="新增"
      :visible.sync="dialogFormAdd"
      width="800px"
      :close-on-click-modal="false"
    >
      <el-form
        :model="quickWordsArray"
        :rules="rules"
        ref="quickWordsArray"
        label-width="160px"
        size="small"
        style="width: 500px"
      >
        <!--   <el-form-item label="区域编号" prop="regionCode">
            <el-input v-model="quickWordsArray.regionCode" autocomplete="off"></el-input>
          </el-form-item>-->
        <el-form-item label="快捷用语" prop="quickWord">
          <el-input
            v-model="quickWordsArray.quickWord"
            maxlength="255"
          ></el-input>
        </el-form-item>
        <el-form-item label="排序" prop="orderIndex">
          <el-input-number
            v-model="quickWordsArray.orderIndex"
            :min="0"
          ></el-input-number>
        </el-form-item>
      </el-form>
      <div slot="footer" style="margin-top: 50px">
        <el-button @click="dialogFormAdd = false" size="mini">取 消</el-button>
        <el-button
          type="primary"
          @click="addSubMit('quickWordsArray')"
          size="mini"
          >确 定</el-button
        >
      </div>
    </el-dialog>
    <!-- 编辑弹出层 -->
    <el-dialog
      title="编辑"
      :visible.sync="dialogFormEdit"
      width="800px"
      :close-on-click-modal="false"
    >
      <el-form
        :model="quickWordsArray"
        :rules="rules"
        ref="quickWordsArray"
        label-width="160px"
        size="small"
        style="width: 500px"
      >
        <!--   <el-form-item label="区域编号" prop="regionCode">
            <el-input v-model="quickWordsArray.regionCode" autocomplete="off"></el-input>
          </el-form-item>-->
        <el-form-item label="快捷用语" prop="quickWord">
          <el-input v-model="quickWordsArray.quickWord"></el-input>
        </el-form-item>
        <el-form-item label="排序" prop="orderIndex">
          <el-input-number
            v-model="quickWordsArray.orderIndex"
            :min="0"
          ></el-input-number>
        </el-form-item>
      </el-form>
      <div slot="footer" style="margin-top: 50px">
        <el-button @click="dialogFormEdit = false" size="mini">取 消</el-button>
        <el-button
          type="primary"
          @click="editSubMit('quickWordsArray')"
          size="mini"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import moment from "moment";
export default {
  data() {
    return {
      dialogFormAdd: false,
      dialogFormEdit: false,
      tableData: [],
      params: {
        quickWord: "",
        page: 1,
        size: 10,
      },
      quickWordsArray: {
        id: "",
        quickWord: "",
        orderIndex: 1,
      },
      rules: {
        /*rquickWordsCode:[
          { required: true, message: '请输入区域编号', trigger: 'blur' },
        ],*/
        quickWord: [
          { required: true, message: "请输入快捷用语", trigger: "blur" },
          { max: 255, message: "最大长度为255个字", trigger: "blur" },
        ],
        orderIndex: [
          { required: true, message: "请填写排序编号", trigger: "blur" },
        ],
      },
      total: 0,
      sizes: [10, 15],
    };
  },
  created() {
    this.queryQuickWordsList();
  },
  methods: {
    queryQuickWordsList() {
      //获取区域列表
      this.axios({
        method: "get",
        url: "/v1/web/quick-word-manage/select-quickWord-list",
        params: this.params,
      }).then((res) => {
        this.tableData = res.data.data.list;
        this.total = res.data.data.total;
      });
    },
    add() {
      //新增弹出层
      this.dialogFormAdd = true;
      this.quickWordsArray = {};
    },
    addSubMit(formName) {
      //新增提交
      this.$refs[formName].validate((valid) => {
        if (!valid) {
          return false;
        } else {
          this.axios({
            method: "post",
            url: "/v1/web/quick-word-manage/add-quickWord",
            data: this.quickWordsArray,
          }).then((res) => {
            if (res.data.code === 200) {
              console.log(res);
              this.dialogFormAdd = false;
              this.$message({
                message: "添加成功",
                type: "success",
              });
              this.queryQuickWordsList();
            } else {
              this.$message.error(res.data.message);
            }
          });
        }
      });
    },
    edit(row) {
      //编辑弹出层
      this.dialogFormEdit = true;
      this.quickWordsArray = row;
    },
    editSubMit(formName) {
      //编辑提交
      this.$refs[formName].validate((valid) => {
        if (!valid) {
          return false;
        } else {
          this.axios({
            method: "post",
            url:
              "/v1/web/quick-word-manage/update-quickWord/" +
              this.quickWordsArray.id,
            data: this.quickWordsArray,
          }).then((res) => {
            if (res.data.code === 200) {
              this.dialogFormEdit = false;
              this.$message({
                message: "修改成功",
                type: "success",
              });
              this.queryQuickWordsList();
            } else {
              this.$message.error(res.data.message);
            }
          });
        }
      });
    },
    deleteRegion(row) {
      //删除提交
      this.axios({
        method: "delete",
        url: "/v1/web/quick-word-manage/delete-quickWord/" + row.id,
      }).then((res) => {
        if (res.data.code === 200) {
          this.queryQuickWordsList();
        } else {
          this.$message({
            type: "error",
            message: res.data.message,
          });
        }
      });
    },
    dateFormat: function (row) {
      if (row.createTime !== null && row.createTime !== undefined) {
        return moment(row.createTime).format("YYYY-MM-DD HH:mm:ss");
      }
    },
    handleCurrentChange(val) {
      this.params.page = val;
      this.queryQuickWordsList();
    },
    sizeChange(val) {
      this.params.size = val;
      this.params.page = 1;
      this.queryQuickWordsList();
    },
    checkData() {
      this.queryQuickWordsList();
    },
    reset() {
      this.params = { page: 1, size: 10 };
      this.queryQuickWordsList();
    },
  },
};
</script>

<style scoped></style>
